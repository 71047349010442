<template>
  <a-spin :spinning="loading">
    <a-row>
      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="iotplt-descriptions"
        >
          <a-descriptions-item label="ICCID/SIMID">
            {{ data.iccid }}
          </a-descriptions-item>
          <a-descriptions-item label="MSISDN">
            {{ data.phone_number }}
          </a-descriptions-item>
          <a-descriptions-item label="IMSI">
            {{ data.imsi }}
          </a-descriptions-item>
          <a-descriptions-item label="短号">
            {{ data.short_number }}
          </a-descriptions-item>
          <a-descriptions-item label="运营商种类">
            {{ data.carrier_type }}
          </a-descriptions-item>
          <a-descriptions-item label="卡品">
            {{ data.card_type_and_shape }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="iotplt-descriptions iotplt-descriptions-max-7"
        >
          <a-descriptions-item label="企业客户" v-if="isShowAgencyName">
            {{ data.agency_name }}
          </a-descriptions-item>
          <a-descriptions-item label="生命周期">
            {{ data.life_cycle }}
          </a-descriptions-item>
          <a-descriptions-item label="联网状态">
            {{ data.net_status }}
          </a-descriptions-item>
          <a-descriptions-item label="单卡限额">
            {{ data.limit_usage_value }}
          </a-descriptions-item>
          <a-descriptions-item label="测试流量" v-if="data.test_traffic != '0.00 KB'">
            {{ data.test_traffic_usage }} / {{ data.test_traffic }}
          </a-descriptions-item>
          <a-descriptions-item label="是否可续期订购">
            {{ data.renewable }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>

      <a-col :span="8">
        <a-descriptions
          :column="1"
          class="iotplt-descriptions iotplt-descriptions-max-8"
        >
          <a-descriptions-item label="入库时间">
            {{ data.allocated_at }}
          </a-descriptions-item>
          <a-descriptions-item label="沉默期截止时间">
            {{ data.silent_period_end_date }}
          </a-descriptions-item>
          <a-descriptions-item label="激活时间">
            {{ data.activated_at }}
          </a-descriptions-item>
          <a-descriptions-item label="服务周期结束时间">
            {{ data.service_end_time }}
          </a-descriptions-item>
          <a-descriptions-item label="预计销卡时间">
            {{ data.expect_cancel_time }}
          </a-descriptions-item>
          <a-descriptions-item label="数据更新时间">
            {{ data.data_updated_at }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <a-row v-if="isVoiceCard">
      <a-divider />
      <a-col>
        <a-row>
          <a-col :span="6">
            <a-button type="primary" @click="getSimCardVoiceWhiteList">查询语音白名单</a-button>
          </a-col>
          <a-col :span="18">
            <a-form
              class="iotplt-compact-form"
              :form="addVoiceWhiteListForm"
              :wrapper-col="{ span: 8 }"
              @submit="addVoiceWhiteListHandleSubmit"
            >
              <a-form-item>
                <a-input-group compact>
                  <a-input
                    v-decorator="['white_number', {
                      normalize: this.$lodash.trim,
                      rules: [
                        { pattern: /^(1[3-9]\d{9})|(0\d{2,3}-?\d{3,8})$/, message: '号码格式不正确' },
                      ]
                    }]"
                    style="width: calc(100% - 80px)"
                  />
                  <a-button type="primary" html-type="submit">新增</a-button>
                </a-input-group>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>

        <a-row v-if="hasVoiceWhiteList">
          <a-form
            class="iotplt-compact-form"
            :form="delVoiceWhiteListForm"
            :wrapper-col="{ span: 24 }"
            @submit="delVoiceWhiteListHandleSubmit"
          >
            <a-form-item>
              <a-checkbox-group
                v-decorator="['whitelist', {
                  rules: [
                    { required: true, message: '请选择号码' },
                  ]
                }]"
                :options="voiceWhiteList"
              />
            </a-form-item>
          </a-form>

          <a-button type="primary" @click="delVoiceWhiteListHandleSubmit" style="margin:10px 0;">删除</a-button>
        </a-row>
        <a-row>
          <div style="color:red;">
            提示：最多可配置5个号码，每月最多可删除1次，每次最多删除2个; 数据提交后可能会有延迟，请稍后刷新
          </div>
        </a-row>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { findSimCardBaseInfo, findSimCardVoiceWhiteList, editVoiceWhiteList } from '@/api/sim_card'

export default {
  name: 'ShowSimCardBaseInfo',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      addVoiceWhiteListForm: this.$form.createForm(this, { name: 'add_voice_whitelist' }),
      delVoiceWhiteListForm: this.$form.createForm(this, { name: 'del_voice_whitelist' }),
      submitting: false,
      isVoiceCard: false,
      voiceWhiteList: [],
      hasVoiceWhiteList: false,
      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    // 企业客户登录不显示企业客户字段
    isShowAgencyName() {
      return this.$store.getters.userRole !== 'agency' && this.$store.getters.userRole !== 'merchant'
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findSimCardBaseInfo(this.id).then((res) => {
        if (res.code === 0) {
          this.data = res.data

          this.isVoiceCard = res.data.is_voice_card
        }
        this.loading = false
      })
    },
    getSimCardVoiceWhiteList() {
      this.loading = true
      this.hasVoiceWhiteList = false

      findSimCardVoiceWhiteList(this.id).then((res) => {
        if (res.code === 0) {
          res.data.forEach((ele, index) => {
            this.voiceWhiteList[index] = {
              label: ele.msisdn,
              value: ele.msisdn
            }
          })

          if (res.data.length > 0) {
            this.hasVoiceWhiteList = true
          }
        }
        this.loading = false
      })
    },
    addVoiceWhiteListHandleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.addVoiceWhiteListForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            oper_type: '1',
            ...values
          }

          editVoiceWhiteList(this.id, data).then((res) => {
            if (res.code === 0) {
              this.addVoiceWhiteListForm.resetFields()
            }
            this.submitting = false
          })
        }
      })
    },
    delVoiceWhiteListHandleSubmit(e) {
      e.preventDefault()

      var vm = this
      this.$confirm({
        title: '确定删除吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          if (vm.submitting) {
            vm.$warning({
              title: '请勿重复提交',
              content: ''
            })
            return
          }

          vm.delVoiceWhiteListForm.validateFields((err, values) => {
            if (!err) {
              vm.submitting = true

              var whitelist = values.whitelist

              if (whitelist.length === 0 || whitelist.length > 2) {
                vm.$warning({
                  title: '至少选择1个，最多两个',
                  content: ''
                })
                vm.submitting = false
                return
              }

              const data = {
                oper_type: '4',
                white_number: whitelist.join('_')
              }

              editVoiceWhiteList(vm.id, data).then((res) => {
                if (res.code === 0) {
                  vm.delVoiceWhiteListForm.resetFields()
                }
                vm.submitting = false
              })
            }
          })
        }
      })
    }
  }
}
</script>
